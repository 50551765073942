import SitePage from './sitepage';

const DemandePage = () => {

    return (
        <SitePage titre="Demande">
            <div>site</div>
        </SitePage>
    );
};

export default DemandePage;

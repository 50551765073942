import { createSlice, isPending, PayloadAction } from '@reduxjs/toolkit';
import { IEntityList, IEntity, IEntityFilter, IEntityListePaginee } from 'core/session/crud.modele';
import CrudService from './crud.service';

export type CrudState = Readonly<typeof initialState>;

export const initialState = {
    userList: {} as IEntityList,
    user : {} as IEntity,
    etatDemandeHistList: {} as IEntityList,
    etatDemandeHist : {} as IEntity,
    alerteList: {} as IEntityList,
    alerte : {} as IEntity,
    mediaList: {} as IEntityList,
    media : {} as IEntity,
    etatDemandeList: {} as IEntityList,
    etatDemande : {} as IEntity,
    objetLitigeList: {} as IEntityList,
    objetLitige : {} as IEntity,
    natureLitigeList: {} as IEntityList,
    natureLitige : {} as IEntity,
    compagnieList: {} as IEntityList,
    compagnie : {} as IEntity,
    brancheList: {} as IEntityList,
    branche : {} as IEntity,
    relanceList: {} as IEntityList,
    relance : {} as IEntity,
    demandeList: {} as IEntityList,
    demande : {} as IEntity,
    civiliteList: {} as IEntityList,
    civilite : {} as IEntity,
    typeDemandeurList: {} as IEntityList,
    typeDemandeur : {} as IEntity,
    resultatDemandeList: {} as IEntityList,
    resultatDemande : {} as IEntity,
    natureSinistreList: {} as IEntityList,
    natureSinistre : {} as IEntity,
    demandeurList: {} as IEntityList,
    demandeur : {} as IEntity,
    actualiteList: {} as IEntityList,
    actualite : {} as IEntity,
    villeList: {} as IEntityList,
    ville : {} as IEntity,
    entiteASupprimer: {} as IEntity,
    entiteSupprimee: false,
    entiteEnregistree: false,
    idEntiteCree : 0,
    filtre: {} as IEntityFilter,
    listePaginee: {} as IEntityListePaginee,
};

export const CrudSlice = createSlice({
    name: 'crud',
    initialState: initialState as CrudState,
    reducers: {
        
        setEntiteASupprimer: (state, action: PayloadAction<IEntity>) => {
            state.entiteASupprimer = action.payload;
        },
        reset: (state , action: PayloadAction<string>) => {
            state[action.payload] ={_type:action.payload} as IEntity ;
        },
        resetEntiteASupprimer: (state) => {
            state.entiteASupprimer = {} as IEntity;
        },
        setPageCourante: (state, action: PayloadAction<number>) => {
            state.filtre.pageCourante = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(CrudService.get.fulfilled, (state, action) => {
                state[action.payload._type] = action.payload;
                state.entiteEnregistree=false;
            })
            .addCase(CrudService.create.fulfilled, (state,action) => {
                state[action.payload._type] = action.payload;
                state.idEntiteCree = action.payload.id;
                state.entiteEnregistree=true;
            })
            .addCase(CrudService.update.fulfilled, (state,action) => {
                state[action.payload._type] = action.payload;
                state.entiteEnregistree=true;
            })
            .addCase(CrudService.del.fulfilled, (state) => {
                state.entiteASupprimer = {} as IEntity;
                state.entiteSupprimee=true;
            })
            .addCase(CrudService.list.fulfilled, (state,action) => {
                state[action.payload.type+'List'] = action.payload;
                state[action.payload.type] = {} as IEntity;
            })
            .addMatcher(isPending(CrudService.list,CrudService.create,CrudService.update,CrudService.del), (state) => {
                state.entiteSupprimee=false;
                state.entiteEnregistree=false;
                state.idEntiteCree =0;
            });
    },
     
});

export const CrudStore = CrudSlice.actions;

export default CrudSlice.reducer;
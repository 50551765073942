import { createSlice, isFulfilled, PayloadAction } from '@reduxjs/toolkit';
import { IDemandeur } from 'modules/demandeur/demandeur.modele';
import { IDemande, IListePagineeDemande, IRequeteDemande } from './demande.modele';
import DemandeService from './demande.service';

export type DemandeState = Readonly<typeof initialState>;

const initialState = {
    demande: {} as IDemande,
    demandeur: {} as IDemandeur,
    requeteDemande: {} as IRequeteDemande,
    listeDemande: {} as IListePagineeDemande,
    demandeCree: {} as IDemande,
    listeFichier: [],
};

export const DemandeSlice = createSlice({
    name: 'demande',
    initialState,
    reducers: {
        initialiser: () => {
            return initialState;
        },
        setPageCourante: (state, action: PayloadAction<number>) => {
            state.requeteDemande.pageCourante = action.payload;
        },
        setRequeteDemande: (state, action: PayloadAction<IRequeteDemande>) => {
            state.requeteDemande = action.payload;
        },
        setListeFichier: (state, action: PayloadAction<any>) => {
            state.listeFichier = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(DemandeService.creer.fulfilled, (state, action) => {
                state.demandeCree = action.payload;
            })
            .addCase(DemandeService.recupererDemandeur.fulfilled, (state, action) => {
                state.demandeur = action.payload;
            })
            .addMatcher(isFulfilled(DemandeService.recupererPourSuivi), (state, action) => {
                state.demande = action.payload;
                state.demandeCree = {} as IDemande;
            });
    },
});

export const DemandeStore = DemandeSlice.actions;

export default DemandeSlice.reducer;

import { Form, DatePicker, Input } from 'antd';
import { APP_DATE_FORMAT } from 'config/constants.config';
import _ from 'lodash';
import moment  from 'moment';
import { useContext, useEffect } from 'react';
import FormulaireValidateur from '../formulaire.validateur';
import 'moment/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';

const ChampDate = (props: any) => {
    const { form, attributes, notifierChangement } = props;
    const style = { style: { ...attributes.style, width: '100%' } };
    const stringDate = Form.useWatch(attributes.name, form);
    useEffect(
        () => {
            if (form.__INTERNAL__.name) {
                const momentDate = stringDate ? moment(stringDate, APP_DATE_FORMAT): null;
                 
                if (_.isArray(attributes.name)) {
                    const v = {};
                    const d = {};
                    d[attributes.sname[1]] = momentDate;
                    v[attributes.name[0]] = d;
                    form.setFieldsValue(v);
                } else {
                    const d = {};
                    d[attributes.sname] = momentDate;
                    form.setFieldsValue(d);
                }

            }
        },
        [ form, stringDate ]
    );

    const changeValue = (momentDate, stringDate) => {
        if (_.isArray(attributes.name)) {
            const v = {};
            const d = {};
            d[attributes.name[1]] = stringDate;
            // d[attributes.sname[1]] = momentDate;
            v[attributes.name[0]] = d;
            form.setFieldsValue(v);
        } else {
            const d = {};
            d[attributes.name] = stringDate;
            // d[attributes.sname] = momentDate;
            form.setFieldsValue(d);
        }


        if (attributes.onChange) {
            attributes.onChange(stringDate);
        }

        if (notifierChangement) {
            notifierChangement();
        }
    };
    const validateur = useContext(FormulaireValidateur);

    const getRules = () => {
        const n =_.isArray(attributes.name)?_.join(attributes.name,'.'):attributes.name;
        if (attributes.requis || (validateur && validateur[n] && validateur[n].requis)) {
            return{ required: true, message: attributes.label + ' est requis.' };
        }
        return {required: false};
    };
    return (
        <div>
            <Form.Item label={attributes.label} name={attributes.sname} rules={[ getRules ]}>
                <DatePicker format={APP_DATE_FORMAT} {...style} onChange={changeValue} disabledDate={props.intervalleDate} disabled={props.attributes.disabled} locale={locale}/>
            </Form.Item>

            <Form.Item name={attributes.name} noStyle >
                <Input style={{ display: 'none' }} />
            </Form.Item>
        </div>
    );
};

export default ChampDate;

import styled from 'styled-components';
import { Col, Row } from 'antd';
import { couleurs } from 'assets/styles/theme';

 

export const SSitePage = styled.div`
  margin:0px;
  padding:0;
`;

export const SSitePageEntete = styled(Row)`
  font-size: 30px;
  font-weight: 300;
  color: ${couleurs.principale};
  text-transform: capitalize;
  font-family: "ROBOTO";
  font-weight: bold ;  
`;

export const SSitePageCorps = styled.div`
    margin-top:8px;
`;
 

export const SH3 = styled.h3`
     color: ${couleurs.principale};
     margin-bottom:0;
`;
  

export const SH4 = styled.h4`
color: ${couleurs.principale};
margin-bottom:0;
`;

export const SDashList = styled.ul`
  list-style: none;
  padding-inline-start: 10px;
  li:before {
    display: inline-block;
    content: "-";
    width: 10px;
}
`;
 


export const SNews = styled.p`
font-weight:bold;
border-bottom: 1px solid #EEE;
padding-bottom:10px;
`;


export const SContactBloc = styled(Col)`

  .wrapper{
    border:1px solid #EEE;
    text-align:center;
    height:170px;
    font-size:16px;
    margin: 0 16px;
      padding:0 10px;
    .image{
      background-color:#fff;
      margin:auto;
      width:100px;
      margin-top:-50px;
      text-align:center;
      img{
        color:${couleurs.principale};
        width:60px;
      }
    }
    .title{
      padding-top:60px;
      font-weight:bold;
    }
  }
`;


import SitePage from './sitepage';
import litiges from 'assets/images/img5.jpg';

const LitigesPage = () => {
    return (
        <SitePage titre="Litiges traités">
            <p>Les litiges peuvent concerner les prestations d’assurance, la gestion du contrat et généralement toute contestation survenant dans l’application du contrat d’assurance ou d’assistance.</p>
            <p>Sont éligibles à l’examen du médiateur, les litiges concernant les personnes physiques ou morales et survenus dans le cadre d’un contrat d’assurance lorsque le montant estimé est supérieur ou égal à 2.000 DH. Ce seuil est porté à 5.000 DH pour les sinistres relevant de l’assurance maladie.</p>
            <p>L’assureur accepte d’office le processus de médiation, dès lors qu’une réclamation est jugée recevable par le médiateur. Toutefois, lorsqu’il s’agit d’une personne morale autre qu’une très petite entreprise (TPE), il est loisible à l’assureur de refuser la médiation.</p>
            <p>On entend par TPE, toute entreprise qui emploie moins de 5 salariés et réalise un chiffre d’affaires d’affaire inférieur à 3 millions DH.</p>
            <div style={{marginTop:'60px',textAlign:'center'}}><img src={litiges} width="70%"/></div>
        </SitePage>
    );
};

export default LitigesPage;

import { AnyAction, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import notificationMiddleWare from 'util/notificationMiddleWare';
import SessionState from 'core/session/session.store';
import CrudState from 'core/session/crud.store';
import DemandeState from 'modules/demande/demande.store';
import EtatDemandeHistState from 'modules/etatDemandeHist/etatDemandeHist.store';
import MediaState from 'modules/media/media.store';

const rootReducer = combineReducers({
    sessionState: SessionState,
    crudState: CrudState,
    demandeState: DemandeState,
    etatDemandeHistState: EtatDemandeHistState,
    mediaState: MediaState
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(notificationMiddleWare)
});

const getStore = () => {
    return store;
};

export type IRootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, IRootState, unknown, AnyAction>;

export default getStore;

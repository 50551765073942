import { Button, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Formulaire, { ChampTexte, ChampTexteLong } from 'composants/formulaire';
import SitePage from './sitepage';
import { SContactBloc, SH4 } from './styles';
import pin from 'assets/images/pin.svg';
import call from 'assets/images/call.svg';
import skype from 'assets/images/skype.svg';
import twitter from 'assets/images/twitter.svg';

const ContactPage = () => {
    const [ form ] = useForm();
    return (
        <SitePage titre="Contact">
            <SH4>Envoyez-nous un E-mail</SH4>
            <div style={{ width: '400px' }}>
                <Formulaire form={form}>
                    <ChampTexte nom="nom" libelle="Nom" />
                    <ChampTexte nom="email" libelle="Email" />
                    <ChampTexte nom="tel" libelle="Tel" />
                    <ChampTexte nom="sujet" libelle="Sujet" />
                    <ChampTexte nom="service" libelle="Service" />
                    <ChampTexteLong nom="commentaire" libelle="Commentaire" />
                </Formulaire>
                <Button type="primary" style={{marginTop:'20px'}}>Envoyer</Button>
            </div>

            <div style={{marginTop:'100px'}}>
                <Row gutter={16}>
                    <SContactBloc span={6}>
                        <div className="wrapper">
                            <div className="image">
                                <img src={pin} alt="pin" />
                            </div>
                            <div className="title">MAPS</div>
                            <div className="content">Casablanca, Maroc</div>
                        </div>
                    </SContactBloc>
                    <SContactBloc span={6}>
                        <div className="wrapper">
                            <div className="image">
                                <img src={call} alt="call" />
                            </div>
                            <div className="title">TÉL</div>
                            <div className="content">+212 522 649 020</div>
                        </div>
                    </SContactBloc>
                    <SContactBloc span={6}>
                        <div className="wrapper">
                            <div className="image">
                                <img src={skype} alt="skype" />
                            </div>
                            <div className="title">VIDEO CALL</div>
                            <div className="content">Médiateur On Skype</div>
                        </div>
                    </SContactBloc>
                    <SContactBloc span={6}>
                        <div className="wrapper">
                            <div className="image">
                                <img src={twitter} alt="twitter" />
                            </div>
                            <div className="title">TWITTER</div>
                            <div className="content">1000 Followers</div>
                        </div>
                    </SContactBloc>
                </Row>
            </div>
        </SitePage>
    );
};

export default ContactPage;

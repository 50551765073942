import SitePage from './sitepage';
import etapes from 'assets/images/etapes.jpg';
const MediationPage = () => {

    return (
        <SitePage titre="Les Étapes de Saisine du Médiateur">
            <div>
                <img src={etapes} />
            </div>
        </SitePage> 
    );
};

export default MediationPage;

import { Form } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import _ from 'config/lodash.config';
import { useContext } from 'react';
import FormulaireValidateur from '../formulaire.validateur';

const ChampTel = (props) => {
    const { form, attributes, notifierChangement } = props;

    const changeValue = (v) => {
        const value = v.unmaskedValue === '' ? null : v.unmaskedValue;
        if (_.isArray(attributes.name)) {
            const v = {};
            const d = {};
            d[attributes.name[1]] = value;
            v[attributes.name[0]] = d;
            form.setFieldsValue(v);
        } else {
            const d = {};
            d[attributes.name] = value;
            form.setFieldsValue(d);
        }

        if (attributes.onChange) {
            attributes.onChange(value);
        }

        if (notifierChangement) {
            notifierChangement();
        }
    };

    const validateur = useContext(FormulaireValidateur);

    const getRules = () => {
        const n = _.isArray(props.attributes.name) ? _.join(props.attributes.name, '.') : props.attributes.name;
        if (props.attributes.requis || (validateur && validateur[n] && validateur[n].requis)) {
            return { required: true, message: props.attributes.label + 'Numero de telephone invalide..', whitespace: true, pattern: /[0-9]{10}/ };
        }
        return { required: false, message: props.attributes.label + 'Numero de telephone invalide.', whitespace: true, pattern: /[0-9]{10}/ };
    };

    return (
        <div>
            <Form.Item label={attributes.label} name={attributes.name} rules={[getRules]}>
                <MaskedInput
                    onChange={changeValue}
                    mask="00 00 00 00 00"
                    placeholder="06 11 22 33 44"
                    maskOptions={{
                        lazy: false,
                        prepare: (str) => {
                            return str.toUpperCase();
                        },
                    }}
                />
            </Form.Item>
        </div>
    );
};

export default ChampTel;

import { createSlice } from '@reduxjs/toolkit';
import { IEtatDemandeHist } from './etatDemandeHist.modele';
import EtatDemandeHistService from './etatDemandeHist.service';

export type EtatDemandeHistState = Readonly<typeof initialState>;

const initialState = {
    etatDemandeHist: {} as IEtatDemandeHist, 
    creationReussie: false, 
};

export const EtatDemandeHistSlice = createSlice({
    name: 'etatDemandeHist',
    initialState,
    reducers: {
        initialiser: () => {
            return initialState;
        },
    },
    extraReducers(builder) {
        builder
            
            .addCase(EtatDemandeHistService.recupererDernierParDemande.fulfilled, (state, action) => {
                state.etatDemandeHist = action.payload;
                state.creationReussie = false;
            })
            .addCase(EtatDemandeHistService.creer.pending, (state) => {
                state.creationReussie = false;
            })
            .addCase(EtatDemandeHistService.creer.fulfilled, (state) => {
                state.creationReussie = true;
            });
    },
});

export const EtatDemandeHistStore = EtatDemandeHistSlice.actions;

export default EtatDemandeHistSlice.reducer;

import styled from 'styled-components';
import { Button } from 'antd';
import { couleurs } from 'assets/styles/theme';

export const SButtonPrincipal = styled(Button)`
  font-weight: 500;
  border-color: ${couleurs.principale};
  &.tracee{
    color: ${couleurs.principale};
    background-color: white;
    &:hover {
      color: white;
      background-color: ${couleurs.principale};
    }
  }
  &.remplie{
    color: white;
    background-color: ${couleurs.principale};
    &:hover {
      color: ${couleurs.principale};
      background-color: white;
    }
}
`;

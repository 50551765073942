import _ from 'lodash';
import { useCallback } from 'react';

const AfficherPremier = ({ si = true, siNul = null, siIdNul = null, children }) => {
    const getElement = useCallback(
        () => {
            const a = _.isNil(siNul) || _.isEmpty(siNul) || siNul === 'null';
            const b = _.isNil(siIdNul) || _.isEmpty(siIdNul) || siIdNul === 'null' || _.isNil(siIdNul.id);
            const e: number = si && a && b ? 0 : 1;
            return children[e];
        },
        [ si, siNul, siIdNul, children ]
    );

    return (<>{getElement()}</>);
};

export default AfficherPremier;

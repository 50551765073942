import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Outlet } from 'react-router';
import LayoutFooter from './lfooter';
import LayoutHeader from './lheader';
import './styles.less';

const LightLayout = () => {
    return (
        <Layout className="site">
            <LayoutHeader />
            <Layout>
                <Content>
                    <Outlet />
                </Content>
            </Layout>
            <LayoutFooter />
        </Layout>
    );
};

export default LightLayout;

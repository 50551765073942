import labels from 'util/labelMapping';
import { SButtonPrincipal } from './styles';

const ActionPrincipale = ({ nom, action, type = 'tracee', libelle = null, icone = null }) => {
    return (
        <span className="btn-wrapper">
            <SButtonPrincipal onClick={action} icon={icone} className={type}>
                {libelle ? libelle : labels.action(nom)}
            </SButtonPrincipal>
        </span>
    );
};

export default ActionPrincipale;

import SitePage from './sitepage';
import processus from 'assets/images/img3.jpg';

const ProcessusPage = () => {

    return (
        <SitePage titre="Le Processus">
            <p>La médiation est un processus par lequel vous demandez au Médiateur de l’assurance de vous accompagner dans la recherche d’une solution au conflit qui vous oppose à une compagnie d’assurance ou d&rsquo;assistance. La médiation est souple et flexible et vous en conserver le plein contrôle du déroulement. Le médiateur est là pour rapprocher les points de vue des parties et leur proposer une solution.</p>
            <p>La médiation comporte de nombreux avantages par rapport aux recours classiques devant les tribunaux, c’est un processus totalement volontaire, rapide et gratuit.</p>
            <p>Enfin, la médiation permet de maintenir des liens plus cordiaux entre les parties.</p>
            <div style={{textAlign:'center'}}><img src={processus} width="70%"/></div>
        </SitePage>
    );
};

export default ProcessusPage;

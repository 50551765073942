import { Collapse } from 'antd';
import SitePage from './sitepage';
import { SDashList, SH3, SH4 } from './styles';

const ChartePage = () => {
    const { Panel } = Collapse;

    return (
        <SitePage titre="La Charte">
            <SH3>Contexte et Objectifs</SH3>
            <p>Sous l’impulsion de l&rsquo;ACAPS, et dans un souci d’amélioration de la qualité des services rendus aux assurés, la Fédération Marocaine des Sociétés d’Assurances et de Réassurance a décidé de mettre en place une structure dédiée à la « Médiation ». Ce dispositif permettra aux assurés et aux tiers de bénéficier d’un procédé conventionnel, alternatif, rapide et gratuit de règlement des litiges avec les entreprises d’assurances</p>
            <SH3>Dispositif de la Médiation en Assurances</SH3>
            <p>A cet effet les entreprises d’assurance ont adopté une charte de médiation instituant un dispositif unique à l’échelle de la profession, confié à une personnalité extérieure nommé par un comité composé de représentant de la Fédération et de l’Autorité de contrôle.</p>
            <Collapse defaultActiveKey={[ '1' ]}>
                <Panel header="Mission du Médiateur" key="1">
                    <p>Le Médiateur a pour mission le traitement à l’amiable des litiges nés d’un contrat d’assurance opposant un particulier à une entreprise d’assurance.</p>
                </Panel>
                <Panel header="Qualités du Médiateur" key="2">
                    <SH4>Qualités du Médiateur</SH4>
                    <p>Le Médiateur souscrit à des normes élevées d’éthique professionnelle. Il s’acquitte de ses tâches avec indépendance, équité, impartialité, discrétion et intégrité sans se laisser influencer par des considérations d’intérêts, d’apparentés ou toute autre considération susceptible de donner lieu à un conflit d’intérêts.</p>
                    <p>Le Médiateur est porteur d’un message modérateur et respectueux. À ce titre, il est attentif à ne as apporter son concours à l’élaboration de stratégies au détriment d’une des parties.</p>
                    <SH4>Confidentialité</SH4>
                    <p>Le Médiateur est lié, dans le cadre de ses fonctions, par l’obligation du secret professionnel et de réserve. Il lui est interdit de divulguer ou de communiquer toute information ou faits confidentiels relatifs aux affaires dont il traite ou auxquels il a eu accès du fait de ses fonctions.</p>
                    <SH4>Conflit d’intérêts</SH4>
                    <p>Le Médiateur est tenu de prendre toutes les dispositions nécessaires pour éviter de se trouver dans une situation de conflits d’intérêts réel, apparent ou potentiel.</p>
                    <p>Dans le cas où le Médiateur conduit une médiation et que celle-ci n’aboutit pas, il ne peut agir en tant qu’arbitre ou conseil dans l’affaire.</p>
                    <SH4>Diligence et responsabilité</SH4>
                    <p>Le Médiateur accorde à tous les réclamants une égalité de traitement.</p>
                    <p>Le traitement des réclamations doit être effectué avec objectivité en vue de rechercher la solution la plus judicieuse.</p>
                    <p>Le Médiateur s’engage à exercer toute la diligence nécessaire afin d’aider les parties à trouver une issue aux différends et litiges qui lui sont soumis. Il se comporte et prend en considération les attentes des réclamants et des entreprises d’assurance de manière à maintenir et renforcer leur confiance.</p>
                    <p>Le médiateur n’a pas vocation à trancher mais à engager les participants à trouver une solution en toute autonomie. Il n&rsquo;est pas tenu de proposer une solution de conciliation</p>
                    <p>Le médiateur ne se prononce pas sur les aspects techniques du dossier et n&rsquo;a pas vocation à se substituer aux experts dont il doit respecter les conclusions et doit éviter la dérive vers de l’assistance, de l’expertise ou du conseil.</p>
                    <p>Les réponses aux réclamations doivent être compréhensibles et motivées. </p>
                </Panel>
                <Panel header="Pouvoirs du Médiateur" key="3">
                    <p>Le Médiateur est responsable de la conduite de sa mission et dispose des moyens et pouvoirs nécessaires pour solliciter des parties tous documents ou renseignements indispensables pour mener à bien sa mission.</p>
                    <p>Le Médiateur peut demander aux parties prenantes de lui communiquer tous documents qu’il estime utiles pour l’accomplissement de sa mission.</p>
                    <p>L’assureur accepte d’office le processus de médiation, dès lors qu’une réclamation est jugée recevable par le médiateur. Toutefois, lorsqu’il s’agit d’une personne morale autre qu’une très petite entreprise (TPE), il est loisible à l’assureur de refuser la médiation.</p>
                    <p>On entend par TPE, toute entreprise qui emploie moins de 5 salariés et réalise un chiffre d’affaires d’affaire inférieur à 3 millions DH.</p>
                </Panel>
                <Panel header="Litiges traités" key="4">
                    <p>Sont éligibles à l’examen du Médiateur, les litiges concernant personnes physiques ou morales et survenus dans le cadre d’un contrat d’assurances souscrit auprès d’une entreprise d’assurance membre de la FMSAR.</p>
                    <p>Le Médiateur ne peut être saisi que pour les réclamations dont le montant est estimé à 2000 dirhams au moins. </p>
                </Panel>
                <Panel header="Conditions préalables à la saisine du Médiateur" key="5">
                    <p>Avant de saisir le Médiateur, le réclamant doit épuiser les procédures internes de règlement des litiges propres à l’entreprise d’assurance.</p>
                    <p>Le réclamant doit transmettre à l’entreprise d’assurance, par courrier recommandé avec accusé de réception, tous les justificatifs qui permettent d’appuyer sa réclamation.</p>
                    <p>Si l’assureur ne donne pas de suite à la réclamation dans le délai de 30 jours à compter de la date de réception du dossier complet y afférent, ou lorsqu’il ne réagit pas dans un délai de 30 jours à compter de la réception de tout document provenant du réclamant, celui-ci peut saisir le Médiateur.</p>
                    <p>La saisine du Médiateur interrompt la prescription.</p>
                    <p>Le Médiateur ne peut être saisi si une action contentieuse a été engagée.</p>
                    <p>Si un réclamant qui a saisi le Médiateur, engage une action en justice pour le même litige, le Médiateur met définitivement fin à la procédure de médiation.</p>
                    <p>Si un réclamant qui a saisi le Médiateur, saisit du même litige l&rsquo;ACAPS, le Médiateur dès qu’il en a eu connaissance, en informe cette dernière qui suspend le traitement jusqu’à la fin du processus de médiation.</p>
                    <p>En cas de demande manifestement infondée ou abusive de la part du réclamant, le Médiateur peut se dessaisir du dossier et en informe les parties. </p>
                </Panel>
                <Panel header="Modalités d’intervention du Médiateur" key="6">
                    <p>Le recours au dispositif de Médiation est volontaire et gratuit pour le réclamant.</p>
                    <SDashList>
                        <li>Soit par courrier recommandé avec accusé de réception,</li>
                        <li>Soit en déposant une demande, par écrit, auprès des services du Médiateur</li>
                        <li>Soit en complétant le formulaire approprié sur le site web du médiateur</li>
                    </SDashList>
                    <br/>
                    <p>Les parties au litige peuvent communiquer les documents et informations nécessaires par voie électronique.</p>
                    <SH4> Dossier de la réclamation</SH4>
                    <p>Toute demande adressée au Médiateur doit préciser de manière claire l’objet du litige et être dûment étayée de l’ensemble des justificatifs nécessaires pour son instruction ainsi que, le cas échéant, de la solution proposée par l’entreprise d’assurance.</p>
                    <p>Le Médiateur peut demander au réclamant et à l’entreprise concernée la production de toute information ou document complémentaire.</p>
                    <p>Le réclamant doit adjoindre à sa demande de saisine du Médiateur, un document dûment signé et légalisé, autorisant expressément l’entreprise d’assurance ou d’assistance à communiquer au Médiateur tous les documents ou informations utiles à l’accomplissement de sa mission.</p>
                    <SH4>Réunion des parties</SH4>
                    <p>Dans le but de proposer la solution la plus appropriée au litige, le Médiateur peut convoquer le réclamant, ainsi que les représentants de l’entreprise, en vue de rapprocher leurs points de vue.</p>
                    <p>Chacune des parties au litige peut se faire représenter ou assister par une personne de son choix dans le cadre de la procédure de médiation.</p>
                    <SH4>Avis du médiateur</SH4>
                    <p>Après avoir instruit le dossier avec le concours des parties, le Médiateur consigne sa proposition de résolution du litige dans un acte portant accord transactionnel et invite le réclamant ainsi que le représentant de l’entreprise à y apposer leurs signatures ; celle du réclamant doit être légalisée.</p>
                    <p>Préalablement à l’exécution par l’entreprise de ses obligations, le réclamant doit renoncer dans l’accord transactionnel à saisir la justice et faire valoir ses droits ultérieurement pour la même affaire.</p>
                    <SH4>Désaccord des parties</SH4>
                    <p>Si l’une des parties refuse la proposition du Médiateur, un procès-verbal constatant la position de chacune des parties est établi et une copie dudit procès-verbal est fournie aux deux parties.</p>
                    <SH4>Fondement de l’avis</SH4>
                    <p>L’avis du Médiateur est établi en considération d’éléments de droit et d’équité et aussi dans un souci de règlement amiable qui ne saurait correspondre à une approche exclusivement judiciaire.</p>
                    <SH4>Délais</SH4>
                    <p>Les parties disposent d’un délai de 21 jours calendaires, après notification de la proposition du Médiateur pour faire connaître leur position.</p>
                    <p>Dans le cas où le montant mis en jeu est inférieur ou égal à 50.000 DH, l’entreprise entérine directement la proposition du Médiateur.</p>
                    <p>Hormis le cas précédent, si l’une des parties au conflit ne donne pas de suite à la proposition de règlement amiable faite par le Médiateur ou si elle refuse expressément cette proposition dans le délai de 21 jours précité, le Médiateur considère que la tentative de Médiation a échoué. Dans ce cas, le dossier est clôturé par le Médiateur. </p>
                </Panel>
                <Panel header="Délai d’instruction du Médiateur" key="7">
                    <p>Le Médiateur rend un avis motivé dans les deux mois qui suivent la réception du dossier dûment complété par les parties prenantes, sauf prorogation dûment motivée.</p>
                </Panel>
            </Collapse>
        </SitePage>
    );
};

export default ChartePage;


import { IEntity } from 'core/session/crud.modele';
import { IReferentiel } from './referentiel.modele';


const creerReferentiel = (entity:IEntity):IReferentiel=>{
    const referentiel:any = {};
    referentiel.id=entity.id;
    referentiel.code=entity.id;
    referentiel.libelle=entity.libelle;
    return referentiel;
};

 
const ReferentielMapper = {
    creerReferentiel,
};

export default ReferentielMapper;
 
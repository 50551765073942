import SitePage from './sitepage';
import { SH4 } from './styles';

const QuandPage = () => {
    return (
        <SitePage titre="Quand saisir le médiateur">
            <SH4>Avant toute démarche auprès du Médiateur vous devez</SH4>
            <ol>
                <li>Respecter la procédure de réclamation prévue par votre contrat d’assurance</li>
                <li>Ne pas avoir entamé d’action en justice ou de réclamation auprès de la ACAPS</li>
                <li>Constituer un dossier complet à destination du Médiateur.</li>
            </ol>
            <p>Pour information, la saisine de la médiation est gratuite.</p>
            <p>Vous pouvez saisir le Médiateur directement. Vous pouvez vous faire représenter ou assister par un tiers de votre choix à tous les stades du processus de médiation.</p>
            <p>Dès réception des documents sur lesquels est fondée la demande, le médiateur de l&rsquo;assurance notifie aux parties par voie électronique ou par courrier simple sa saisine.</p>
            <p>Une fois la saisine effectuée, les échanges avec le service du Médiateur s’effectuent par voie postale ou électronique.</p>
            <p>Le Médiateur étudie les dossiers à partir des documents qui lui ont été communiqués par les parties. Il dispose d’un délai de deux mois à compter de la réception du dossier entier pour rendre un avis.</p>
            <p>L’avis rendu par le Médiateur est établi en droit et en équité, il est communiqué en même temps aux participants. Cet avis ne s’impose pas aux parties, sauf pour l’entreprise d’assurance lorsque le montant en jeu est inférieur à 50 000 dirhams.</p>
            <p>L’avis est définitif, le Médiateur ne pourra pas répondre à de nouvelles demandes des parties sur le même litige ni ne pourra connaître de nouveaux développements.</p>
            <p>En cas de désaccord persistant, le recours devant les tribunaux reste possible.</p>
            <p><span style={{color:'#b81d1d',fontWeight:'bold'}}>IMPORTANT</span> : Le délai de prescription est suspendu le temps que dure la médiation.</p>
        </SitePage>
    );
};

export default QuandPage;

import { Col } from 'antd';
import { SSitePage, SSitePageCorps, SSitePageEntete } from './styles';

const SitePage = (props) => {
    document.title = 'LE MEDIATEUR DE l\'ASSURANCE';

    return (
        <SSitePage>
            <SSitePageEntete>
                <Col flex="none" > {props.titre} </Col>
            </SSitePageEntete>
            <SSitePageCorps>{props.children}</SSitePageCorps>
        </SSitePage>
    );
};

export default SitePage;

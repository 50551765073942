import labels from 'util/labelMapping';
import { SEncadre, SEncadreEntete, SEncadreCorps } from './styles';

const Encadre = ({ titre, style = {}, children }) => {
    return (
        <SEncadre style={style}>
            <SEncadreEntete>
                <span>{labels.titre(titre)}</span>
            </SEncadreEntete>
            <SEncadreCorps>{children}</SEncadreCorps>
        </SEncadre>
    );
};

export default Encadre;

import * as _ from 'lodash';
import moment from 'moment';

declare module 'lodash' {
    interface LoDashStatic {
        removeNonSerialisable<T>(input: T): T;
    }
}

namespace LoDash {
    export function removeNonSerialisable<T>(objet: T) {
        const resultat = {};
        Object.keys(objet).forEach((key) => {
            const val = objet[key];
            if(_.isPlainObject(val) && !(val instanceof moment)){
                resultat[key] = removeNonSerialisable(val);
            }else if (!(val instanceof moment)) {
                resultat[key] = val;
            } 
        });
        return resultat;
    }
}

_.mixin(
    Object.keys(LoDash).reduce((object, key) => {
        object[key] = LoDash[key];
        return object;
    }, Object.create(null))
);

export default _;

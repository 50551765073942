import ReferentielMapper from 'core/referentiel/referentiel.mapper';
import axios from 'axios';
import { IReferentiel } from './referentiel.modele';
import { IEntity } from 'core/session/crud.modele';

const listerReferentiel = (reference: string, arg?: string): Promise<IReferentiel[]> => {
    const referencePromise  = axios.get<IEntity[]>(`api/pbl/${reference}/listForReference?cacheBuster=${new Date().getTime()}`);
    return new Promise<IReferentiel[]>((resolve) => {
        referencePromise.then((response) => {
            const entityListe: IEntity[] = response.data;
            const listeReferentiel = [] as IReferentiel[];
            entityListe.forEach((entity: IEntity) => {
                listeReferentiel.push(ReferentielMapper.creerReferentiel(entity));
            });
            resolve(listeReferentiel);
        });
    });
};

const listerEnum = (reference: string): Promise<IReferentiel[]> => {
    const referencePromise  = axios.get<IEntity[]>(`api/pbl/${reference}/listForReference?cacheBuster=${new Date().getTime()}`);
    return new Promise<IReferentiel[]>((resolve) => {
        referencePromise.then((response) => {
            const entityListe: IEntity[] = response.data;
            const listeReferentiel = [] as IReferentiel[];
            entityListe.forEach((entity: IEntity) => {
                listeReferentiel.push(ReferentielMapper.creerReferentiel(entity));
            });
            resolve(listeReferentiel);
        });
    });
};
 


const ReferentielService = {
    listerReferentiel,
    listerEnum
};

export default ReferentielService;

import { Form, Input } from 'antd';
import _ from 'lodash';
import { useContext } from 'react';
import FormulaireValidateur from '../formulaire.validateur';

const ChampEmail = (props) => {
    const validateur = useContext(FormulaireValidateur);

    const getRules = () => {
        const n = _.isArray(props.attributes.name) ? _.join(props.attributes.name, '.') : props.attributes.name;
        if (props.attributes.requis || (validateur && validateur[n] && validateur[n].requis)) {
            return [
                { required: true, message: props.attributes.label + ' est requis.', whitespace: true },
                { type: 'email', message: props.attributes.label + ' n est pas un email valide.', whitespace: true },
            ];
        }
        return [{ required: false }, { type: 'email', message: props.attributes.label + ' n est pas un email valide.', whitespace: true }];
    };

    const valueChanged = (a) => {
        if (props.attributes.onChange) {
            props.attributes.onChange(a);
        }
    };
    return (
        <Form.Item {...props.attributes} rules={getRules()}>
            <Input style={props.attributes.style} disabled={props.attributes.disabled} placeholder={props.attributes.placeholder} onBlur={valueChanged} />
        </Form.Item>
    );
};

export default ChampEmail;

import { Col } from 'antd';
import labels from 'util/labelMapping';
import { SPanneau, SPanneauEntete, SPanneauCorps } from './styles';

const Panneau = ({ titre = null, libelle =null, type='light', blocAction = null, children }) => {
    return (
        <SPanneau>
            { (titre || libelle) && (
                <SPanneauEntete align="middle" className={type}>
                    <Col flex="none"> {libelle || labels.titre(titre)}</Col>
                    <Col flex="auto" style={{textAlign:'right'}}> {blocAction}</Col>
                </SPanneauEntete>
            )}
            <SPanneauCorps>
                <Col span={24}>{children}</Col>
            </SPanneauCorps>
        </SPanneau>
    );
};

export default Panneau;

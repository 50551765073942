import { Form, Row } from 'antd';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import labels from 'util/labelMapping';
import { SColFormulaireInline } from './styles';

const FormulaireInline = ({ form, siChange = null, nombreColonne = 1, nom = 'noname', style = null, children }) => {

    const [ items, setItems ] = useState([]);
    const [ hiddenItems, setHiddenItems ] = useState([]);

    const extract = useCallback((cprops): any => {
        const cname = cprops.nom ? cprops.nom : cprops.reference;
        if (cname) {
            const attributes = {} as any;
            const i = cname.lastIndexOf('.');
            attributes.name = i > 0 ? _.split(cname, '.') : cname;
            if (cprops.libelle) {
                attributes.label = cprops.libelle;
            } else {
                const label = i > 0 ? cname.substr(i + 1) : cname;
                attributes.label = labels.get(label);
            }

            if (_.isArray(attributes.name)) {
                const array = _.clone(attributes.name);
                attributes.slabel = labels.get(array[array.length - 1]);
                array[array.length - 1] = array[array.length - 1] + 'Libelle';
                attributes.sname = array;
            } else {
                attributes.slabel = labels.get(attributes.name);
                attributes.sname = attributes.name + 'Libelle';
            }

            attributes.reference = cprops.reference?cprops.reference:cprops.nom;
            attributes.requis = cprops.requis;
            attributes.key = cname;
            attributes.style = cprops.style;
            attributes.onChange = cprops.siChange;
            attributes.disabled = cprops.disabled;
            attributes.placeholder = cprops.placeholder;

            return attributes;
        } else {
            return { ...cprops };
        }
    }, []);

    useEffect(
        () => {
            const hiddenListe = [];
            const liste = [];
            let i = 1;
            React.Children.forEach(children, (c) => {
                // const colWidth = "1 0 "+Math.floor(100 / children.length) +"%";

                if (c.type.name === 'ChampCache') {
                    hiddenListe.push(
                        <span key={i++}>
                            {React.cloneElement(c, { attributes: extract(c.props), form, notifierChangement: siChange })}
                        </span>
                    );
                } else if(_.isNil(c.props.invisible) || !c.props.invisible ){
                    liste.push(
                        <SColFormulaireInline key={i++}>
                            {React.cloneElement(c, { attributes: extract(c.props), form, notifierChangement: siChange })}
                        </SColFormulaireInline>
                    );
                }
            });
            setHiddenItems(hiddenListe);
            setItems(liste);
        },
        [ children, nombreColonne, extract, siChange, form ]
    );

    return (
        <Form form={form} name={nom} style={style} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} layout="vertical" onFieldsChange={siChange}>
            <Row>{items}</Row>
            <div>{hiddenItems}</div>
        </Form>
    );
};

export default FormulaireInline;

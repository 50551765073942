import styled from 'styled-components';
import { couleurs } from 'assets/styles/theme';
import { Col, Row } from 'antd';
 

export const SFiltre = styled.div`
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid #E0E0E0;
    background-color: #FFF;
    .anticon-close {
      padding:3px;
    border-radius: 3px;
    
    }
    &:hover {
    .anticon-close {
      color:white;
      background-color:${couleurs.principale};
    }
  }
`;

export const SNomFiltre = styled(Col)`
    color: #bbb;
    font-weight: 700;
    font-size: 12px;
`;

export const SValeurFiltre = styled(Col)`
    color: #444;
    font-weight: 500;
    font-size: 14px;
`;

export const SFermerFiltre = styled(Col)`
 
`;



export const SEntete = styled(Row)`
  border-bottom: 1px solid ${couleurs.principale};
  background-color: #FEFEFE;
  cursor:pointer;
  padding: 5px;
  color:${couleurs.principale};
  font-weight: 700;
  font-size: 14px;
  .anticon-edit {
    padding:3px;
    border-radius: 3px;
  }
  &:hover {
    .anticon-edit {
      color:white;
      background-color:${couleurs.principale};
    }
  }

`;

export const SPanneau = styled.div`
    border-radius: 3px;
    border: 1px solid #eee;
    background-color: #F6F6F6;
    cursor:pointer;
    margin: 10px;   
`;

export const SCorps = styled.div`
  padding:10px;
  cursor:pointer;   
`;
 


export const SColFormulaireInline = styled(Col)`
  padding-right:10px;
  label{
    padding-left:2px;
    color: #999;
    font-weight: 600;
    font-size: 12px;
  }
`;
import axios from 'axios';
import { API_CALL_TIMEOUT, API_URL } from 'config/constants.config';
import QueryString from 'qs';

axios.defaults.timeout = API_CALL_TIMEOUT;
axios.defaults.baseURL = API_URL;
axios.defaults.paramsSerializer = params => {
    return QueryString.stringify(params, {arrayFormat: 'indices'});
};

export const JSON_HEADER ={ headers: { 'Content-Type': 'application/json' } };

const initAxios = () => {
    
    const onResponseSuccess = (response) => response;
    const onResponseError = (error) => {
        return Promise.reject(error);
    };
    axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default initAxios;

import { Row } from 'antd';
import { SCorps, SDialog, SEntete } from './styles';

const DialogueInformation = ({ visible, titre, nomAction = 'fermer', action, entete, largeur = 600, children }) => {
    return (
        <SDialog visible={visible} onOk={action} okText={nomAction} closable={false} width={largeur} title={titre} cancelButtonProps={{ style: { display: 'none' } }}>
            <Row>
                <SEntete span="24">{entete}</SEntete>
            </Row>
            <Row>
                <SCorps span="24">{children}</SCorps>
            </Row>
        </SDialog>
    );
};
export default DialogueInformation;

import SitePage from './sitepage';
import { SDashList, SH3 } from './styles';
import acteurs from 'assets/images/img4.jpg';

const ActeursPage = () => {
    return (
        <SitePage titre="Les Acteurs">
            <SH3>Les réclamants</SH3>
            <SDashList>
                <li>Assuré, souscripteur et bénéficiaire de contrat</li>
                <li>Tiers bénéficiaire de prestations d’assurance (victime d’accident)</li>
            </SDashList>
            <SH3>Les compagnies d’assurance ou d’assistance.</SH3>
            <div style={{marginTop:'60px',textAlign:'center'}}><img src={acteurs} width="70%"/></div>
        </SitePage>
    );
};

export default ActeursPage;
